/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, Headline, InfoItem, Obl, CareInstructions, CareItem} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoItem) _missingMdxReference("InfoItem", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Traction alopecia is a form of alopecia, or mechanical damage to the hair follicle results in hair loss, caused by repeated tension or pulling.")), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Causes"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Traction alopecia can be caused by wearing the same hairstyle for long periods of time."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Hairstyles tugging on the hair such as braids, weaves, cornrows, tight pony-tails, heavy locks, hair rollers,etc.are common culprits; the constant pulling on the hair causes loosening of the hair from the follicles."), "\n"), "\n")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "When the hair is pulled in the same direction, ", React.createElement(_components.strong, null, "the hair shaft and hair follicle can become damaged"), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "Early on, you may notice small flesh colored or white bumps around hair follicles where the hair is pulled most tightly. Other symptoms on the scalp may include redness and tenderness."), "\n"), "\n")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Over time, the hairline can become ", React.createElement(_components.strong, null, "thin and sparce, recede, or develop bald patches"), ".")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure04",
    image: "figure04",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure04",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "The prolonged inflammation and repeated hair tension may lead to gradual hair loss most noticeable along the hairline.")), React.createElement(Pin, {
    id: "figure04",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "video01",
    video: "ijaulX3e5Wg",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "video01",
    type: "start",
    height: 300,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Hear Dr. Chesahna Kindred of the ", React.createElement(Obl, {
    to: "https://kindredhairandskin.com/",
    title: "Dr. Kindred's Practice"
  }, "Kindred Hair and Skin Center"), " share more on how address traction alopecia.")), React.createElement(Pin, {
    id: "video01",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "Treatment"), React.createElement(_components.p, null, "Initially, traction alopecia is temporary, but if tight hairstyling habits are unchanged, the hair loss may become permanent."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Early stages: limit or eliminate any hairstyles that pull on the hair. Wear hair in loose styles (especially overnight)."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Reduce the amount of chemicals and heat used during styling."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Treat scalp tenderness, bumps or inflammation with ", React.createElement(Obl, {
    to: "https://amzn.to/3tMxZAq",
    title: "Amazon Affiliate Link"
  }, "topical antibiotics"), " or ", React.createElement(Obl, {
    to: "https://my.clevelandclinic.org/health/drugs/4812-corticosteroids",
    title: "Cleveland Clinic Article"
  }, "topical corticosteroids"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Moderate to severe stages: oral antibiotics, injected corticosteroids, or ", React.createElement(Obl, {
    to: "https://amzn.to/3gtruPR",
    title: "Amazon Affiliate Link"
  }, "topical minoxidil"), " may be necessary. The best treatment of traction alopecia is prevention."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "For more info: ", React.createElement(Obl, {
    to: "https://skinofcolorsociety.org/patient-dermatology-education/traction-alopecia/",
    title: "Skin Of Color Society explanation of traction alopecia"
  }, "https://skinofcolorsociety.org/patient-dermatology-education/traction-alopecia/")), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
